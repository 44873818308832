import {
  Dialog,
  PopoverDisclosure,
  PopoverProvider,
  usePopoverStore,
} from "@ariakit/react";
import { AnimatePresence, motion } from "framer-motion";
import { X } from "lucide-react";
import * as toast from "~/components/toast";
import { useCurrentUser } from "~/hooks/use-current-user";
import { cn } from "~/util/css";
import { getClientOriginUrl } from "~/util/url";
import { Button } from "./button";
import { AnimatedPopover } from "./post";

interface PostModalProps {
  open: boolean;
  onClose: () => void;
}

export default function InviteModal(props: PostModalProps) {
  const [currentUser] = useCurrentUser();
  const referralLink = `${getClientOriginUrl()}/invite/${
    currentUser?.referralCode
  }`;
  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    toast.ok({ text: "Referral link copied" });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={cn("relative w-4/5  md:w-[450px]")}
      render={(dialogProps) => (
        <div
          className={cn(
            "flex fixed z-50 top-0 p-4 lg:p-0 w-full h-full bg-primary/50 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <div className="flex flex-col">
        <AnimatePresence>
          <motion.div
            className="relative w-full h-64"
            initial={{ opacity: 0, y: -400 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
          >
            <div className="w-full py-6 px-10 bg-violet-100 rounded-xl shadow border-2 border-blue-300 flex-col justify-start items-center gap-8 inline-flex">
              <div className="self-stretch h-16 flex-col justify-start items-center gap-3 flex">
                <div className="self-stretch h-5 p-1 justify-end items-start gap-2.5 inline-flex" />
                <div className="self-stretch text-center text-black sm:text-4xl text-2xl font-bold">
                  Referral Link
                </div>
              </div>
              <div className="absolute top-5 right-5 z-10">
                <button type="button" onClick={props.onClose}>
                  <X />
                </button>
              </div>
              <div className="absolute -bottom-4 -right-4 rotate-[-11deg] sm:w-[76px] sm:h-[76px] w-14 h-14 bg-[url(/icons/pop.svg)] bg-cover bg-center z-10" />
              <div className="absolute top-20 -right-10 rotate-[9deg] sm:w-[68px] sm:h-[68px] w-14 h-14 bg-[url(/icons/login-pop7.png)] bg-cover bg-center z-10" />
              <div className="absolute top-[92px] -left-8  sm:w-20 sm:h-20 w-14 h-14 bg-[url(/icons/referral.png)] bg-cover bg-center z-10" />
              <div className="text-violet-700 sm:text-base text-sm font-bold text-center">
                {referralLink}
              </div>
              <div className="justify-center items-start gap-3 inline w-40 items-center flex">
                <Button
                  className="hover:bg-gray-300 p-3 w-full flex items"
                  onClick={() => handleCopyLink()}
                  type="button"
                >
                  <strong>Copy link</strong>
                </Button>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </Dialog>
  );
}
